<template>
  <div class="block-services" v-if="services && !Array.isArray(services)">
    <UltraBox :theme="services.details ? services.details.theme : 'default'">
      <header class="block-services-header" data-aos="zoom-in-up">
        <div class="block-services-header-content">
          <h2 class="fn-color mb-10"
              :class="services.details.titleColor ?? 'primary'"
              v-if="services.title">{{ services.title }}</h2>
          <p class="w-75 fn-color" v-if="services.description"
             :class="services.details.textColor ?? 'black'">{{ services.description }}</p>
        </div>
        <div class="block-services-header-action fn-color" :class="services.details.actionColor ?? 'white'">
          <UltraAction v-if="services.action" :action="services.action" />
        </div>
      </header>
      <section class="block-services-section gb-wrapper">
        <Carousel :itemsToShow="4" :autoplay="7000">
          <Slide v-for="item in services.items" :key="item.id">
            <UltraCardService :service="item" />
          </Slide>
          <template #addons>
            <Pagination />
            <Navigation />
          </template>
        </Carousel>
      </section>
    </UltraBox>
  </div>
  <div class="block-services" v-else-if="services && Array.isArray(services)">
    <UltraBox v-for="(postItem, index) in services"
              :theme="postItem.details ? postItem.details.theme : 'default'"
              :key="postItem.id ?? index">
      <header class="block-services-header" data-aos="zoom-in-up">
        <div class="block-services-header-content">
          <h2 class="fn-color mb-10"
              :class="postItem.details.titleColor ?? 'primary'"
              v-if="postItem.title">{{ postItem.title }}</h2>
          <p class="fn-color" v-if="postItem.description"
             :class="postItem.details.textColor ?? 'black'">{{ postItem.description }}</p>
        </div>
        <div class="block-services-header-action fn-color" :class="postItem.details.actionColor ?? 'white'">
          <UltraAction v-if="postItem.action" :action="postItem.action" />
        </div>
      </header>
      <section class="block-services-section fn-color" :class="postItem.details.textColor ?? 'black'">
        <Carousel :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="item in postItem.items" :key="item.id">
            <UltraCardService :service="item" />
          </Slide>
          <template #addons>
            <Pagination />
            <Navigation />
          </template>
        </Carousel>
      </section>
    </UltraBox>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import UltraBox from "@/components/UltraBox";
import UltraAction from "@/components/UltraAction";
import UltraCardService from "@/components/UltraCardService";

export default {
  name: "BlockServices",
  data() {
    return {
      settings: {
        itemsToShow: 1,
        snapAlign: 'left',
        wrapAround: true,
      },
      breakpoints: {
        600: {
          itemsToShow: 4,
          snapAlign: 'left',
          wrapAround: false,
        },
      },
    };
  },
  components: {
    UltraCardService,
    UltraAction,
    UltraBox,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  computed: {
    services() {
      return this.$store.getters['services'];
    }
  },
}
</script>

<style scoped>
.block-services {
  text-align: center;
  width: 100%;
  height: auto;
  position: relative;
  display: inline-block;
}
.block-services-header {
  width: 100%;
  margin-bottom: 40px;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
}
.block-services-header-content {
  display: inline-flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
}
.block-services-header-action {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.block-services-section {
  width: 100%;
  position: relative;
}


@media only screen and (max-width: 600px) {
  .block-services-section {
    padding: 0 10px;
  }
  .block-services-header {
    flex-wrap: wrap;
  }
}
</style>