<template>
  <UltraHeader ref="header" />
  <UltraSlide ref="slide" />
  <BlockServices ref="services" />
  <BlockHighlight ref="highlight" />
  <BlockMovie ref="movie" />
  <BlockModules ref="modules" />
  <BlockDrops ref="drops" />
  <BlockOutdoor ref="outdoor" />
  <BlockPosts ref="posts" />
  <BlockBrands ref="brands" />
  <BlockComments ref="comments" />
  <BlockSocial ref="social" />
  <BlockFrame ref="frame" />
  <BlockFooter ref="footer" />
  <BlockFixedButtons />
  <UltraModal />
</template>

<script>
import mixins from "@/mixins";
import UltraHeader from "@/components/UltraHeader";
import UltraSlide from "@/components/UltraSlide";
import BlockHighlight from "@/blocks/BlockHighlight";
import BlockMovie from "@/blocks/BlockMovie";
import BlockDrops from "@/blocks/BlockDrops";
import BlockOutdoor from "@/blocks/BlockOutdoor";
import BlockComments from "@/blocks/BlockComments";
import BlockFooter from "@/blocks/BlockFooter";
import BlockFixedButtons from "@/blocks/BlockFixedButtons";
import UltraModal from "@/components/UltraModal";
import BlockModules from "@/blocks/BlockModules";
import BlockPosts from "@/blocks/BlockPosts";
import BlockSocial from "@/blocks/BlockSocial";
import BlockServices from "@/blocks/BlockServices";
import BlockFrame from "@/blocks/BlockFrame";
import BlockBrands from "@/blocks/BlockBrands";

export default {
  name: 'App',
  mixins: [mixins],
  components: {
    BlockBrands,
    BlockFrame,
    BlockServices,
    BlockSocial,
    BlockPosts,
    BlockModules,
    UltraModal,
    BlockFixedButtons,
    BlockFooter,
    BlockComments,
    BlockOutdoor,
    BlockDrops,
    BlockMovie,
    BlockHighlight,
    UltraSlide,
    UltraHeader,
  },
}
</script>

<style>
@import "@/assets/app.css";
</style>