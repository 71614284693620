<template>
  <div class="ultra-card-service" v-if="service" @click="execAction(service.action)">
    <div class="ultra-card-service-thumb">
      <div class="ultra-card-service-thumb-wrapper">
        <div class="card-border-services">
          <div class="card-icon-services">
            <img class="card-icon-services-image" :src="service.image" :title="service.title" :alt="service.title" />
          </div>
        </div>
      </div>
    </div>
    <header class="ultra-card-service-header">
      <h3 class="fn-color primary-dark tx-uppercase" v-if="service.title">{{ service.title }}</h3>
      <p class="mt-10" v-if="service.description">{{ service.description }}</p>
    </header>
  </div>
</template>

<script>
import mixins from "@/mixins";

export default {
  name: "UltraCardService",
  mixins: [mixins],
  props: {
    service: {
      type: Object,
      required: true,
    },
  }
}
</script>

<style scoped>
.ultra-card-service {
  width: 100%;
  text-align: center;
  position: relative;
  border: 2px solid transparent;
  border-radius: 12px;
  margin: 5px;
  transition: all .3s ease-in-out;
  cursor: pointer;
  background-image: radial-gradient(#FFFFFF 25%, #9E9E9E 100%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.ultra-card-service:hover {
  border: 2px solid transparent;
  transition: all .3s ease-in-out;
}
.ultra-card-service-header {
  position: relative;
  text-align: center;
  padding: 10px;
}
.ultra-card-service-header p {
  font-size: .8rem;
  line-height: .8rem;
}
.ultra-card-service-thumb {
  width: 100%;
  height: auto;
  padding-bottom: 100%;
  position: relative;
}
.ultra-card-service-thumb-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.card-border-services {
  width: calc(100% - 60px);
  height: calc(100% - 60px);
  object-fit: cover;
  border-radius: 50%;
  margin: 30px;
  position: relative;
}
.card-border-services:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--color-secondary);
}
.card-border-services:before {
  content: '';
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: -15px;
  top: -15px;
  background-color: var(--color-primary-dark);
  transition: all .6s ease-in-out;
}
li:nth-child(4n+1) .card-border-services:before {
  transform: translate3d(0, 9px, 0);
  transition: all .3s ease-in-out;
}
li:nth-child(4n+2) .card-border-services:before {
  transform: translate3d(-7px, -7px, 0);
  transition: all .3s ease-in-out;
}
li:nth-child(4n+3) .card-border-services:before {
  transform: translate3d(7px, 7px, 0);
  transition: all .3s ease-in-out;
}
li:nth-child(4n+4) .card-border-services:before {
  transform: translate3d(0, -9px, 0);
  transition: all .3s ease-in-out;
}
li:hover .card-border-services:before {
  transform: translate3d(0, 0, 0) !important;
  transition: all .6s ease-in-out;
}
.card-icon-services {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
}
.card-icon-services-image {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center center;
}
.card-icon-services-image[src*="-agenda."] {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-position: bottom center;
}
.card-icon-services-image[src*="-balcao."] {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-position: bottom center;
}
.card-icon-services-image[src*="-shop."] {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-position: bottom center;
}
.card-icon-services-image[src*="-saude."] {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-position: 24% center;
}
</style>