<template>
  <UltraBox class="block-frame" theme="primary" v-if="frame">
    <h2 class="fn-color white" v-if="frame.title">{{ frame.title }}</h2>
    <div class="block-frame-frame" data-aos="zoom-in-up">
      <iframe :id="frame.id" :src="frame.url" width="100%" height="100%" scrolling="no" frameborder="0"></iframe>
    </div>
  </UltraBox>
</template>

<script>
import UltraBox from "@/components/UltraBox";

export default {
  name: "BlockFrame",
  components: {
    UltraBox,
  },
  computed: {
    frame() {
      return this.$store.getters['iframe'];
    },
  },
}
</script>

<style scoped>
.block-frame-frame {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  list-style: none;
  padding: 10px;
  margin: 40px 0;
  border-radius: 10px;
  flex-wrap: wrap;
  background-color: white;
}
h2 {
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 4px;
}
</style>