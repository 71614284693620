<template>
  <UltraBox class="block-brands" v-if="brands" theme="gray-tiny">
    <header class="block-brands-header" v-if="brands.title" data-aos="zoom-in-up">
      <h2 class="fn-color primary tx-uppercase">{{ brands.title }}</h2>
    </header>
    <section class="block-brands-section">
      <Carousel :settings="settings" :breakpoints="breakpoints">
        <Slide v-for="item in brands.items" :key="item.id">
          <UltraCardBrand :service="item" />
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </section>
  </UltraBox>
</template>

<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import UltraBox from "@/components/UltraBox";
import UltraCardBrand from "@/components/UltraCardBrand";

export default {
  name: "BlockBrands",
  data() {
    return {
      settings: {
        itemsToShow: 3,
        snapAlign: 'center',
      },
      breakpoints: {
        600: {
          itemsToShow: 6,
          snapAlign: 'center',
        },
      },
    };
  },
  components: {
    UltraCardBrand,
    UltraBox,
    Carousel,
    Slide,
    Navigation,
  },
  computed: {
    brands() {
      return this.$store.getters['brands'];
    }
  },
}
</script>

<style scoped>
.block-brands {
  text-align: center;
}
.block-brands-header {
  width: 100%;
  margin-bottom: 40px;
}
.block-brands-section {
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  color: var(--color-white);
}

@media only screen and (max-width: 600px) {
  .block-brands-section {
    padding: 0 10px;
  }
}
</style>